import { NotificationManager } from "react-notifications";
import { UploadExcelWithPositionsRequest } from "../models/drafts";
import { postData } from "../stores/BaseStore";

export const uploadExcelWithPositions = async (
  request: UploadExcelWithPositionsRequest
): Promise<any> => {
  try {
    const response = await postData("/testnode/f/excel", request).ready;
    if (!response.ok) {
      throw new Error();
    }
    const data = await response.json();
    return data;
  } catch (error) {
    NotificationManager.error("Ошибка при отправке excel");
  }
};
