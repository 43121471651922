import { CircularProgress } from "@material-ui/core";
import DialogContent from "@material-ui/core/DialogContent";
import Modal from "@material-ui/core/Modal";
import { useEffect, useState } from "react";
import { Draft2 } from "../../models/drafts";
import { ProductPosition, SearchResult } from "../../models/product";
import AuthStore from "../../stores/AuthStore";
import DraftStore from "../../stores/DraftStore";
import ProductStore from "../../stores/ProductStore";
import useWhStocksStore from "../../zustand/whStocks";
import DraftUndReservesSelector from "../drafts/DraftUndReservesSelector";
import styles from "./Listing.module.css";
import ProductLine from "./ProductLine";

const Listing = () => {
  const authStore = AuthStore.Instance;
  const store = ProductStore.Instance;
  const draftStore = DraftStore.Instance;
  const user = authStore.getCurrentUser();
  const [results, setResults] = useState<SearchResult>();
  const [loading, setLoading] = useState<boolean>(false);
  const [modalOpen, setModalOpen] = useState(false);
  const stocks = useWhStocksStore((state) => state.stocks);
  const stocksLoading = useWhStocksStore((state) => state.loading);
  const [draftPosition, setDraftPosition] = useState<
    ProductPosition | undefined
  >(undefined);
  const [draftAmount, setDraftAmount] = useState<number>(1);

  useEffect(() => {
    const subscription = store.getSearch().subscribe((value) => {
      setResults(value);
    });
    const loadingSubscription = store.getSearchLoading().subscribe((value) => {
      setLoading(value);
    });
    return () => {
      subscription.unsubscribe();
      loadingSubscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleToDraftAndReservesClick(
    position: ProductPosition,
    amount: number
  ) {
    setDraftPosition(position);
    setDraftAmount(amount);
    setModalOpen(true);
  }

  function handleDraftSelect(draft: Draft2) {
    if (draft && draftPosition) {
      draftStore.addToDraft(
        draftPosition.uuid,
        draft.draftuuid,
        draftAmount || 1
      );
    }
    setModalOpen(false);
  }

  if (loading) {
    return (
      <div className={styles.spinnerWrapper}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {results?.firtsPage && results?.firtsPage.length > 0 && (
        <div className={styles.pane}>
          {results?.firtsPage?.map((i) => (
            <ProductLine
              key={i.id}
              position={i}
              stock={stocks[i.uuid]}
              stocksLoading={stocksLoading}
              onDraftAndReservesClick={(positiom, amount) => {
                handleToDraftAndReservesClick(positiom, amount);
              }}
              course_our={user.UserData?.course_our}
            />
          ))}
        </div>
      )}
      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <DraftUndReservesSelector
            loading={false}
            onDraftSelect={(draft) => {
              handleDraftSelect(draft);
            }}
          />
        </DialogContent>
      </Modal>
    </>
  );
};

export default Listing;
