import { Button, DialogContent } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import { Draft2, DraftPosition2 } from "../../models/drafts";
import { MappedWhStocks } from "../../models/info";
import DraftStore from "../../stores/DraftStore";
import ListingControl from "../listingcontrol/ListingControl";
import MerchDraftItemsListLine from "./MerchDraftItemsListLine";
import styles from "./MerchDraftItemsListLine.module.css";
import MerchDraftItemsListLineAdd from "./MerchDraftItemsListLineAdd";
import MerchDraftItemsLineHeader from "./MerchDraftItemsListLineHeader";

interface MerchDraftItemsListProps {
  draft?: Draft2;
  whStocksForDraft: MappedWhStocks;
  stocksLoading: boolean;
}

const MerchDraftItemsList = (props: MerchDraftItemsListProps) => {
  const { draft, whStocksForDraft, stocksLoading } = props;
  const store = DraftStore.Instance;

  const [modalOpen, setModalOpen] = React.useState(false);
  const [searchVisible, setSearchVisible] = React.useState(false);
  const [draftPosition, setDraftPosition] = React.useState<
    DraftPosition2 | undefined
  >(undefined);

  function handleDeleteClick(position: DraftPosition2) {
    setDraftPosition(position);
    setModalOpen(true);
  }
  function handleDeleteConfirm(position?: DraftPosition2) {
    if (position?.uuid && draft?.draftuuid) {
      store.deleteLineFormDraft(position.uuid, draft.draftuuid);
    }
    setModalOpen(false);
  }
  function handleDeleteCancel(position?: DraftPosition2) {
    setModalOpen(false);
  }

  function handleChangeAmount(position?: DraftPosition2, delta?: number) {
    if (position?.uuid && draft?.draftuuid) {
      store.addToDraft(position.uuid, draft.draftuuid, delta || 1, false);
    }
  }

  function handleSetAmount(position?: DraftPosition2, amount?: number) {
    if (position?.uuid && draft?.draftuuid) {
      store.addToDraft(position.uuid, draft.draftuuid, amount || 1, true);
    }
  }

  if (!draft) return <div>Загрузка...</div>;

  return (
    <div className={styles.pane}>
      <MerchDraftItemsLineHeader />
      <div className={styles.scroll_pane}>
        {draft?.items?.map((val, index, arr) => (
          <MerchDraftItemsListLine
            key={val.productId}
            position={val}
            am={val.amount || 0}
            processing={val.serverProcessing || false}
            order={index + 1}
            onDelete={(item) => {
              handleDeleteClick(item);
            }}
            onChangeAmount={(pos, delta) => {
              handleChangeAmount(pos, delta);
            }}
            onSetAmount={(pos, amount) => {
              handleSetAmount(pos, amount);
            }}
          />
        ))}
        <MerchDraftItemsListLineAdd
          order={(draft?.items?.length || 0) + 1}
          onAdd={() => {
            setSearchVisible(true);
          }}
        />

        <div className={styles.search_and_total}>
          <div className={styles.search}>
            {searchVisible && (
              <ListingControl
                stocksLoading={stocksLoading}
                whStocksForDraft={whStocksForDraft}
                onAddClick={(position, amount) => {
                  setSearchVisible(false);
                  store.addToDraft(
                    position.uuid,
                    draft.draftuuid,
                    amount || 1,
                    true
                  );
                }}
              />
            )}
          </div>
          <div className={styles.total}>
            <span className={styles.total_lbl}>
              {draft?.ndses
                ? draft?.ndses === "Без НДС"
                  ? "Без НДС"
                  : "НДС " + draft?.ndses
                : ""}
            </span>
            <span className={styles.total_sums}>{draft?.ndstotal || 0}₽</span>
            {/* <span className={styles.total_lbl}>Доставка</span>
                    <span className={styles.total_sums}>???₽</span> */}
            <div className={styles.total_separator}></div>
            <span className={styles.total_lbl}>Итого к оплате</span>
            <span className={styles.total_sums}>{draft.sum}₽</span>
          </div>
        </div>
      </div>

      <Modal
        open={modalOpen}
        onClose={() => {
          setModalOpen(false);
        }}
      >
        <DialogContent>
          <div className={styles.modal_pane}>
            <span>Подтвердите удаление позиции:</span>
            <div className={styles.modal_pane_buttons}>
              <Button
                className={styles.modal_yes_button}
                variant='contained'
                color='secondary'
                disableElevation
                onClick={() => {
                  handleDeleteConfirm(draftPosition);
                }}
              >
                Удалить
              </Button>
              <Button
                className={styles.modal_no_button}
                variant='contained'
                color='primary'
                disableElevation
                onClick={() => {
                  handleDeleteCancel();
                }}
              >
                Отмена
              </Button>
            </div>
          </div>
        </DialogContent>
      </Modal>
    </div>
  );
};

export default MerchDraftItemsList;
