import { BehaviorSubject } from "rxjs";
import { LoginResponse, User } from "../models/user";

class AuthStore {
  private static _instance: AuthStore;
  public static get Instance() {
    return this._instance || (this._instance = new AuthStore());
  }

  private userSubject: BehaviorSubject<User>;

  public constructor() {
    const defUser = {
      inProcess: false,
      loggedIn: false,
      ErrorMessage: "",
      UserData: undefined,
      // SessionToken: '3daefe8e-39ba-46c0-82aa-43449fa1af8a', // remove for login
      SessionToken: "",
    };

    try {
      const user: User = JSON.parse(localStorage.getItem("user") || "");
      this.userSubject = new BehaviorSubject<User>(user ? user : defUser);
    } catch (ex) {
      this.userSubject = new BehaviorSubject<User>(defUser);
    }
  }

  public getUser() {
    return this.userSubject.asObservable();
  }

  public getCurrentUser() {
    return this.userSubject.value;
  }

  // private cst = '';
  public get currentSessionToken() {
    return this.getCurrentUser()?.SessionToken || "";
  }

  public tryLogin(login: string, password: string, rememberMe: boolean): void {
    const returnError = (err: string) => {
      this.userSubject.next({
        inProcess: false,
        loggedIn: false,
        ErrorMessage: err,
        UserData: undefined,
      });
    };
    this.userSubject.next({
      inProcess: true,
      loggedIn: false,
      ErrorMessage: "",
      UserData: undefined,
    });
    postData("/testnode/f/loginb2b", { login: login, password: password })
      .then((x) => {
        if (!x.ok) {
          returnError(x.statusText);
        } else {
          return x.json();
        }
      })
      .then((x: LoginResponse) => {
        if (x) {
          if (x.ErrorMessage) {
            returnError(x.ErrorMessage);
          } else if (!x.SessionToken) {
            if (x.ErrorMessage) {
              returnError(x.ErrorMessage);
            } else {
              returnError("Произошла ошибка (нет токена). Попробуйте еще раз.");
            }
          } else {
            // this.cst = x.SessionToken;
            const user = {
              inProcess: false,
              loggedIn: true,
              ErrorMessage: "",
              UserData: x.UserData,
              SessionToken: x.SessionToken,
            };
            if (rememberMe) {
              localStorage.setItem("user", JSON.stringify(user));
            } else {
              localStorage.removeItem("user");
            }
            this.userSubject.next(user);
          }
        }
      })
      .catch((error) => {
        returnError(error);
      });
  }

  public logout() {
    localStorage.removeItem("user");
    localStorage.removeItem("main-wh");
    // TODO: clear or not?
    // localStorage.removeItem('cart-items');
    this.userSubject.next({
      inProcess: false,
      loggedIn: false,
      ErrorMessage: "",
      UserData: undefined,
    });
  }
}

async function postData(url = "", data = {}) {
  return fetch(url, {
    method: "POST",
    mode: "cors",
    // cache: 'no-cache',
    credentials: "same-origin",
    headers: { "Content-Type": "application/json" },
    referrerPolicy: "no-referrer",
    body: JSON.stringify(data),
  });
  // return response.json();
}

export default AuthStore;
