import { useEffect, useState } from "react";
import { MappedWhStocks } from "../../models/info";
import { ProductPosition, SearchResult } from "../../models/product";
import AuthStore from "../../stores/AuthStore";
import ProductStore from "../../stores/ProductStore";
import styles from "./LCListing.module.css";
import LCProductLine from "./LCProductLine";

interface LCListingProps {
  onAddClick: (position: ProductPosition, amount: number) => void;
  whStocksForDraft: MappedWhStocks;
  stocksLoading: boolean;
}

const LCListing = (props: LCListingProps) => {
  const { onAddClick, whStocksForDraft, stocksLoading } = props;
  const store = ProductStore.Instance;
  const authStore = AuthStore.Instance;
  const user = authStore.getCurrentUser();
  const [results, setResults] = useState<SearchResult>();

  useEffect(() => {
    const subscription = store.getSearch().subscribe((value) => {
      setResults(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {results?.firtsPage && (
        <div className={styles.pane}>
          {results?.firtsPage?.map((i) => (
            <LCProductLine
              stocksLoading={stocksLoading}
              stock={whStocksForDraft[i.uuid]}
              key={i.id}
              position={i}
              onAddClick={(positiom, amount) => {
                onAddClick(positiom, amount);
              }}
              course_our={user.UserData?.course_our}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default LCListing;
