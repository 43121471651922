import ListFilter from '../listlisting/ListFilter';
import ListListing from '../listlisting/ListListing';
import ListPaginator from '../listlisting/ListPaginator';
import styles from './ListPage.module.css';

const ListPage = () => {
    return <div className={styles.page}>
        <ListFilter></ListFilter>
        <ListListing></ListListing>
        <ListPaginator></ListPaginator>
    </div>
}
export default ListPage;