import { create } from "zustand";
import { devtools } from "zustand/middleware";
import { MappedWhStocks } from "../models/info";

interface WhStocksState {
  stocks: MappedWhStocks;
  loading: boolean;
  setWhStocks: (value: MappedWhStocks) => void;
  setLoading: (value: boolean) => void;
}

const useWhStocksStore = create<WhStocksState>()(
  devtools((set) => ({
    stocks: {},
    loading: false,
    setWhStocks(value: MappedWhStocks) {
      set({ stocks: value });
    },
    setLoading(value: boolean) {
      set({ loading: value });
    },
  }))
);

export default useWhStocksStore;
