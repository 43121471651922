import { useEffect } from "react";
import ProductStore from "../../stores/ProductStore";
import Listing from "../listing/Listing";
import ListingSearchAndFilterPane from "../listing/ListingSearchAndFilterPane";
import ProductLineHeader from "../listing/ProductLineHeader";
import styles from "./SearchPage.module.css";

const SearchPage = () => {
  const productStore = ProductStore.Instance;

  useEffect(() => {
    return () => {
      productStore.setSuggest([]);
      productStore.setSearch(undefined);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.page}>
      <ListingSearchAndFilterPane />
      <ProductLineHeader />
      <Listing />
    </div>
  );
};
export default SearchPage;
