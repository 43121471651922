export type NewProductsType = {
  data: ProductDetails[];
  error: null | string;
  loading: boolean;
};

export interface ProductDetails {
  id: string;
  name: string;
  model: string;
  usdPrice: number;
  rubPrice: number;
  mainChars: { name: string; value: string }[];
  compatWith: { group: string; models: string[] }[];
  notCompatWith: { group: string; models: string[] }[];
  chars: { name: string; value: string }[];
  imgUrl: string;
  uuid: string;
  oemcodes: Array<string>;
  promoPrice: Nullable<number>;
  promoDateExpired: Nullable<string>;
  labels: Array<string>;
  icons: Array<string>;
  color: Color | undefined;
  brand?: string;
}

export enum Color {
  cyan = "cyan",
  magenta = "magenta",
  yellow = "yellow",
  black = "black",
  colored = "colored",
}

export interface ProductPosition {
  id: string;
  name: string;
  model: string;
  imgUrl?: string;
  oemcodes: Array<string>;

  labels?: string[]; // 'new', 'sale'
  color?: Color;

  usdPrice?: number;
  rubPrice?: number;

  amountLeft?: number;
  uuid: string;
  promoPrice?: Nullable<number>;
  promoDateExpired: Nullable<string>;
  brand?: string;
}

export interface CartPosition extends ProductPosition {
  qty: number;
  totalusd: number | "-";
  totalrub: number | "-";
}

export interface Facet {
  selected?: boolean;
  value?: string;
  count?: number;
}

export interface FacetGroup {
  name: string;
  displayName?: string;
  items?: Facet[];

  isBinary?: boolean;
}

export interface SearchRequest {
  facets: FacetGroup[];
  inStock: false;
  order: string;
}

export interface SearchResult {
  ids: string[]; // all ids in result
  firtsPage: ProductPosition[]; // first n items

  facets: FacetGroup[];
  total: number;
}

export interface Favs {
  json_fav: {
    skus: string[];
  };
}

export const productSorts = {
  ds: "По умолчанию",
  pa: "По цене (по возрастанию)",
  pd: "По цене (по убыванию)",
  // Не завязать с основным складом
  // ca: 'По наличию (по возрастанию)',
  // cd: 'По наличию (по убыванию)',
};

export const productSortsRe = {
  "По умолчанию": "ds",
  "По цене (по возрастанию)": "pa",
  "По цене (по убыванию)": "pd",
  "По наличию (по возрастанию)": "ca",
  "По наличию (по убыванию)": "cd",
};

export const productSortsServ = {
  ds: null,
  pa: { sort_field: "sort_price", sort_dir: "asc" },
  pd: { sort_field: "sort_price", sort_dir: "desc" },
  ca: { sort_field: "sort_stock", sort_dir: "asc" },
  cd: { sort_field: "sort_stock", sort_dir: "desc" },
};

export type Label = "new" | "EOL" | "sale" | "bestseller";

export interface ItemData {
  amount: number;
  inFavs: boolean;
  inCart: boolean;
}
