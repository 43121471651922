import { BehaviorSubject, Subject } from "rxjs";
import { CatalogueItem, ListRequest, ListResponse } from "../models/catalogue";
import {
  Favs,
  NewProductsType,
  ProductDetails,
  SearchResult,
} from "../models/product";
import { MIN_SYMBOLS_FOR_SEARCH } from "../utils/const";
import AuthStore from "./AuthStore";
import { getData, postData } from "./BaseStore";

class ProductStore {
  private static _instance: ProductStore;
  public static get Instance() {
    return this._instance || (this._instance = new ProductStore());
  }

  private currentSessionToken = "";
  public constructor() {
    AuthStore.Instance.getUser().subscribe((x) => {
      this.currentSessionToken = x?.SessionToken || "";
      if (this.currentSessionToken) {
        this.initFavs();
      }
    });
  }

  private currentSuggest?: { abort: () => void; ready: Promise<Response> };
  private suggestSubject = new BehaviorSubject<string[]>([]);
  public getSuggest() {
    return this.suggestSubject.asObservable();
  }
  public setSuggest(value: Array<string>) {
    this.suggestSubject.next(value);
  }
  public suggest(query: string) {
    if (!query || query.length < MIN_SYMBOLS_FOR_SEARCH) {
      this.suggestSubject.next([]);
      return;
    }

    if (this.currentSuggest) {
      this.currentSuggest.abort();
    }
    const fin = () => {
      this.currentSuggest = undefined;
    };
    this.currentSuggest = postData("/testnode/f/suggest", { query: query });
    this.currentSuggest.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: string[]) => {
        this.suggestSubject.next(x || []);
        fin();
      })
      .catch(() => {
        fin();
      });
  }

  private currentProductDetails?: {
    abort: () => void;
    ready: Promise<Response>;
  };
  private productDetailsSubject = new BehaviorSubject<
    ProductDetails | undefined
  >(undefined);
  public getProductDetails() {
    return this.productDetailsSubject.asObservable();
  }
  public productDetails(id: string) {
    if (this.currentProductDetails) {
      this.currentProductDetails.abort();
    }
    const fin = () => {
      this.currentProductDetails = undefined;
    };
    this.currentProductDetails = postData("/testnode/f/productdetails", {
      SessionToken: this.currentSessionToken,
      id: id,
    });
    this.currentProductDetails.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: ProductDetails | undefined) => {
        this.postProcessDetails(x);
        this.productDetailsSubject.next(x);
        fin();
      })
      .catch((error) => {
        fin();
      });
  }
  private postProcessDetails(x: ProductDetails | undefined) {
    if (!x) {
      return;
    }
    x.icons = [];
    if (x.chars.find((i) => i.name === "STMC" && i.value === "true")) {
      x.icons.push("http://t2rus.ru/images/_/icons/stmc.png");
    }
    if (x.chars.find((i) => i.name === "Новый" && i.value === "true")) {
      x.icons.push("http://t2rus.ru/images/_/icons/new.png");
    }
    if (x.chars.find((i) => i.name === "Чип" && i.value === "true")) {
      x.icons.push("http://t2rus.ru/images/_/icons/chip.png");
    }

    const clr = x.chars.find((i) => i.name === "Цвет");
    if (clr) {
      if (clr.value === "Черный") {
        x.icons.push("http://t2rus.ru/images/_/icons/black.png");
      } else if (clr.value === "Голубой") {
        x.icons.push("http://t2rus.ru/images/_/icons/cyan.png");
      } else if (clr.value === "Пурпурный") {
        x.icons.push("http://t2rus.ru/images/_/icons/magenta.png");
      } else if (clr.value === "Желтый") {
        x.icons.push("http://t2rus.ru/images/_/icons/yellow.png");
      } else if (clr.value === "Трехцветный") {
        x.icons.push("http://t2rus.ru/images/_/icons/color.png");
      }
    }
    x.chars.forEach((i) => {
      if (i.value === "false") {
        i.value = "Нет";
      }
      if (i.value === "true") {
        i.value = "Да";
      }
    });
  }

  private currentNewProductsDetails?: {
    abort: () => void;
    ready: Promise<Response>;
  };
  private newProductsDetailsSubject = new BehaviorSubject<NewProductsType>({
    data: [],
    error: null,
    loading: false,
  });
  public getNewProductDetails() {
    return this.newProductsDetailsSubject.asObservable();
  }
  public newProductDetails(label: string) {
    if (this.currentNewProductsDetails) {
      this.currentNewProductsDetails.abort();
    }
    const fin = () => {
      this.currentNewProductsDetails = undefined;
    };
    if (this.currentSessionToken) {
      this.newProductsDetailsSubject.next({
        data: [],
        loading: true,
        error: null,
      });
      this.currentNewProductsDetails = postData("/testnode/f/lookforlabels", {
        SessionToken: this.currentSessionToken,
        labels: [label],
      });
      this.currentNewProductsDetails.ready
        .then((x) => (x.ok ? x.json() : fin()))
        .then((x: ProductDetails[] | undefined) => {
          x?.forEach((i) => this.postProcessDetails(i));
          this.newProductsDetailsSubject.next({
            error: null,
            loading: false,
            data: x || [],
          });
        })
        .catch((error: Error) => {
          this.newProductsDetailsSubject.next({
            data: [],
            error: error.message,
            loading: false,
          });
        })
        .finally(() => {
          fin();
        });
    }
  }

  private currentSearch?: { abort: () => void; ready: Promise<Response> };
  private searchSubject = new BehaviorSubject<SearchResult | undefined>(
    undefined
  );
  private currentSearchLoading = new BehaviorSubject<boolean>(false);
  public getSearchLoading() {
    return this.currentSearchLoading.asObservable();
  }
  public getSearch() {
    return this.searchSubject.asObservable();
  }

  public setSearch(value?: SearchResult) {
    this.searchSubject.next(value);
  }
  public search(query: string) {
    if (!query || query.length < MIN_SYMBOLS_FOR_SEARCH) {
      this.searchSubject.next(undefined);
      return;
    }
    if (this.currentSearch) {
      this.currentSearch.abort();
    }
    const fin = () => {
      this.currentSearch = undefined;
      this.currentSearchLoading.next(false);
    };
    this.currentSearchLoading.next(true);
    this.currentSearch = postData("/testnode/f/search", {
      SessionToken: this.currentSessionToken,
      query: query,
    });
    this.currentSearch.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: SearchResult | undefined) => {
        this.searchSubject.next(x);
        fin();
      })
      .catch(() => {
        fin();
      });
  }

  private currentListing?: {
    abort: () => void;
    ready: Promise<Response>;
    hash?: string;
  };
  private listingSubject = new BehaviorSubject<ListResponse | undefined>(
    undefined
  );
  public getListing() {
    return this.listingSubject.asObservable();
  }
  public doListing(req: ListRequest) {
    const hash = this.listRequestHash(req);
    if (this.currentListing) {
      if (this.currentListing.hash === hash) {
        return;
      }
      this.currentListing.abort();
    }
    this.clearListing();
    const fin = () => {
      this.currentListing = undefined;
    };
    this.currentListing = postData("/testnode/f/getlist", {
      SessionToken: this.currentSessionToken,
      req: req,
    });
    this.currentListing.hash = hash;
    this.currentListing.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: ListResponse | undefined) => {
        this.listingSubject.next(x);
        fin();
      })
      .catch((error) => {
        fin();
      });
  }
  public clearListing() {
    this.listingSubject.next(undefined);
  }
  private listRequestHash(req: ListRequest) {
    req.filters?.forEach((f) => f?.values.sort());
    req.filters?.sort((a, b) => a?.term?.localeCompare(b?.term || "") || 0);
    const ftrls = req.filters
      ?.map((f) => f.term + "__" + f?.values?.join("_"))
      .join("___");
    const sort = (req.sort_dir || "") + "_" + req.sort_field;
    const pge = req.from + "_" + req.size + ";";
    return ftrls + "#" + sort + "#" + pge;
  }
  private listingViewType = false;
  private listingViewTypeSubject = new Subject<boolean>();
  public getListingViewType() {
    return this.listingViewTypeSubject.asObservable();
  }
  public getListingViewTypeVal() {
    return this.listingViewType;
  }
  public setListingViewType(viewType: boolean) {
    this.listingViewType = viewType;
    this.listingViewTypeSubject.next(viewType);
  }

  private currentFavs?: { abort: () => void; ready: Promise<Response> };
  private favQueue: { sku: string; remove: boolean }[] = [];
  private favs = new BehaviorSubject<string[]>([]);
  private combineFavs(skus: string[]) {
    let rez = [...skus];
    this.favQueue.forEach((i) => {
      if (i.remove) {
        rez = rez.filter((item) => item !== i.sku);
      } else if (rez.indexOf(i.sku) < 0) {
        rez.push(i.sku);
      }
    });
    return rez;
  }
  public getFavs() {
    return this.favs.asObservable();
  }
  public initFavs() {
    if (this.currentFavs) {
      this.currentFavs.abort();
    }

    const fin = () => {
      this.currentFavs = undefined;
      if (this.favQueue.length > 0) {
        this.addToFavs();
      }
    };
    this.currentFavs = postData("/testnode/f/getfavs", {
      SessionToken: this.currentSessionToken,
    });
    this.currentFavs.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: Favs | undefined) => {
        this.favs.next(this.combineFavs(x?.json_fav?.skus || []));
        fin();
      })
      .catch((error) => {
        fin();
      });
  }
  public addToFavs(id?: string, remove = false) {
    if (id !== undefined) {
      console.log(remove ? "removed " + id : "added " + id);
      this.favQueue.push({ sku: id, remove: remove });
    }
    if (this.currentFavs) {
      return;
    }

    const fin = () => {
      this.currentFavs = undefined;
      if (this.favQueue.length > 0) {
        this.addToFavs();
      }
    };

    const newFavs = this.combineFavs(this.favs.value);
    this.favQueue = [];
    // console.log('before post:' + newFavs.join(','));
    this.currentFavs = postData("/testnode/f/savefavs", {
      SessionToken: this.currentSessionToken,
      json_fav: { skus: newFavs },
    });
    this.favs.next(newFavs);

    this.currentFavs.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: Favs | undefined) => {
        if (x) {
          const combined = this.combineFavs(x?.json_fav?.skus || []);
          this.favs.next(combined);
          // console.log('received:' + (x?.json_fav?.skus || []).join(','));
          // console.log('new combined:' + (x?.json_fav?.skus || []).join(','));
        }
        fin();
      })
      .catch((error) => {
        fin();
      });
  }

  private currentMenu?: { abort: () => void; ready: Promise<Response> };
  private menuSubject = new BehaviorSubject<CatalogueItem[] | undefined>(
    undefined
  );
  private menuSubjectLoading = new BehaviorSubject<boolean>(false);
  public getMenu() {
    return this.menuSubject.asObservable();
  }
  public getMenuLoading() {
    return this.menuSubjectLoading.asObservable();
  }
  public initMenu() {
    if (this.currentMenu) {
      // this.currentMenu.abort();
      return; // once is enough
    }

    const fin = () => {
      this.menuSubjectLoading.next(false);
      // this.currentMenu = undefined;
    };
    this.menuSubjectLoading.next(true);
    this.currentMenu = getData("/testnode/f/getmenu");
    this.currentMenu.ready
      .then((x) => (x.ok ? x.json() : fin()))
      .then((x: CatalogueItem[] | undefined) => {
        this.menuSubject.next(x);
        fin();
      })
      .catch((error) => {
        fin();
        this.currentMenu = undefined;
      });
  }
}

export default ProductStore;
