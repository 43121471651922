import { DialogContent } from "@material-ui/core";
import Modal from "@material-ui/core/Modal";
import React from "react";
import { ListResponse } from "../../models/catalogue";
import { Draft2 } from "../../models/drafts";
import { ProductPosition } from "../../models/product";
import AuthStore from "../../stores/AuthStore";
import DraftStore from "../../stores/DraftStore";
import ProductStore from "../../stores/ProductStore";
import useWhStocksStore from "../../zustand/whStocks";
import DraftUndReservesSelector from "../drafts/DraftUndReservesSelector";
import ProductLine from "../listing/ProductLine";
import ShortProductCard from "../listing/ShortProductCard";
import styles from "./ListListing.module.css";

const ListListing = () => {
  const authStore = AuthStore.Instance;
  const user = authStore.getCurrentUser();
  const store = ProductStore.Instance;
  const draftStore = DraftStore.Instance;
  const [results, setResults] = React.useState<ListResponse | undefined>(
    undefined
  );
  const [listViewType, setListViewType] = React.useState<boolean>(
    store.getListingViewTypeVal()
  );
  const stocksLoading = useWhStocksStore((state) => state.loading);
  const stocks = useWhStocksStore((state) => state.stocks);
  const [modalOpen, setModalOpen] = React.useState(false);

  const [draftPosition, setDraftPosition] = React.useState<
    ProductPosition | undefined
  >(undefined);

  const [draftAmount, setDraftAmount] = React.useState<number>(1);

  React.useEffect(() => {
    const subscription = store.getListing().subscribe((value) => {
      setResults(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const subscription = store.getListingViewType().subscribe((value) => {
      setListViewType(value);
    });
    return () => {
      subscription.unsubscribe();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function handleToDraftAndReservesClick(
    position: ProductPosition,
    amount: number
  ) {
    setDraftPosition(position);
    setDraftAmount(amount);
    setModalOpen(true);
  }

  function handleDraftSelect(draft: Draft2) {
    if (draft && draftPosition) {
      draftStore.addToDraft(
        draftPosition.uuid,
        draft.draftuuid,
        draftAmount || 1
      );
    }
    setModalOpen(false);
  }

  // const modal = <Modal open={modalOpen}>
  //     <DraftUndReservesSelector onDraftSelect={(draft) => { console.log(draft); }}></DraftUndReservesSelector>
  // </Modal>

  if (!listViewType) {
    return (
      <div className={styles.pane}>
        {results?.items?.map((i) => (
          <ProductLine
            stocksLoading={stocksLoading}
            key={i.id}
            position={i}
            onDraftAndReservesClick={(positiom, amount) => {
              handleToDraftAndReservesClick(positiom, amount);
            }}
            // TODO: add stock
            stock={stocks[i.uuid]}
            course_our={user.UserData?.course_our}
          />
        ))}
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <DialogContent>
            <DraftUndReservesSelector
              loading={false}
              onDraftSelect={(draft) => {
                handleDraftSelect(draft);
              }}
            />
          </DialogContent>
        </Modal>
      </div>
    );
  } else {
    return (
      <div className={styles.also_pane}>
        {results?.items?.map((i) => (
          <ShortProductCard
            key={i.id}
            position={i}
            onDraftAndReservesClick={(position, amount) => {
              handleToDraftAndReservesClick(position, amount);
            }}
            course_our={user.UserData?.course_our}
          />
        ))}
        <Modal
          open={modalOpen}
          onClose={() => {
            setModalOpen(false);
          }}
        >
          <DialogContent>
            <DraftUndReservesSelector
              loading={false}
              onDraftSelect={(draft) => {
                handleDraftSelect(draft);
              }}
            />
          </DialogContent>
        </Modal>
      </div>
    );
  }
};

export default ListListing;
