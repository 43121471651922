import { Button, CircularProgress, Select } from "@material-ui/core";
import { useCallback, useEffect, useState } from "react";
import { NotificationManager } from "react-notifications";
import { uploadExcelWithPositions } from "../../services/draftServices";
import { getWhList, getWhStocks } from "../../services/infoServices";
import AuthStore from "../../stores/AuthStore";
import InfoStore from "../../stores/InfoStore";
import { EXCEL_FILE_FORMATS, MILLISECOND_IN_HOUR } from "../../utils/const";
import { transformToBase64 } from "../../utils/utils";
import useMainWhStore from "../../zustand/mainWhStore";
import useWhStocksStore from "../../zustand/whStocks";
import styles from "./MainLayout.module.css";

type props = {
  header: JSX.Element;
  content: JSX.Element;
  footer: JSX.Element;
};

const ORDER_GUIDS = [
  "a332b097-29b9-11ef-a94b-00155d4b0700",
  "dc053f37-29b9-11ef-a94b-00155d4b0700",
  "1aaa6e87-29bb-11ef-a94b-00155d4b0700",
];

const MainLayout = ({ header, content, footer }: props) => {
  const authStore = AuthStore.Instance;
  const infoStore = InfoStore.Instance;
  const token = authStore.currentSessionToken;
  const whList = useMainWhStore((state) => state.whList);
  const mainWh = useMainWhStore((state) => state.mainWh);
  const setWhList = useMainWhStore((state) => state.setWhList);
  const setMainWh = useMainWhStore((state) => state.setMainWh);
  const setWhStocks = useWhStocksStore((state) => state.setWhStocks);
  const setLoadingWhStocks = useWhStocksStore((state) => state.setLoading);
  const [isReloadButton, setIsReloadButton] = useState<boolean>(false);
  const [isReloadingData, setIsReloadingData] = useState<boolean>(false);
  const [isUploadExcel, setIsUploadExcel] = useState<boolean>(false);
  const [excelFile, setExcelFile] = useState<File | null>(null);
  const [isUploadingExcel, setIsUploadingExcel] = useState<boolean>(false);
  const [orderGuid, setOrderGuid] = useState<string>(ORDER_GUIDS[0]);

  const reload = () => {
    setIsReloadingData(true);
    fetch("/testnode/f/reloaditems", {
      method: "GET",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers: { "Content-Type": "application/json" },
      referrerPolicy: "no-referrer",
    })
      .then(async (resp) => {
        if (resp.status === 404) {
          throw new Error(resp.statusText);
        } else {
          const res = await resp.json();
          if (!res.aborted) {
            NotificationManager.success("Позиции успешно перезагружены!");
          } else {
            throw new Error("aborted");
          }
        }
      })
      .catch((error) => {
        NotificationManager.error(`Ошибка при перезагрузке ${error.message}`);
      })
      .finally(() => {
        setIsReloadingData(false);
      });
  };

  const handleFile = (event: React.ChangeEvent<HTMLInputElement>) => {
    let file = null;
    if (event.target.files && event.target.files.length) {
      file = event.target.files[0];
      if (!EXCEL_FILE_FORMATS.includes(file.type)) {
        setExcelFile(null);
        NotificationManager.error(
          "Пожалуйста выберите файл формата xls или xlsx"
        );
        return;
      }
      setExcelFile(file);
    }
  };

  const uploadFile = useCallback(async () => {
    setIsUploadingExcel(true);
    try {
      if (excelFile) {
        const base64string = await transformToBase64(excelFile);
        if (!base64string) {
          throw new Error("Null при получении base64 строки");
        }
        const response = await uploadExcelWithPositions({
          base64string: base64string as string,
          order_guid: orderGuid,
        });
        console.log("response: ", response);
      }
    } catch (error) {
      NotificationManager.error(`Ошибка : ${error}`);
    } finally {
      setIsUploadingExcel(false);
    }
  }, [excelFile, orderGuid]);

  useEffect(() => {
    const getData = async () => {
      if (token && (!mainWh || !whList)) {
        try {
          const whListData = await getWhList(token);
          if (whListData && whListData[0]) {
            setWhList(whListData);
            if (!mainWh) {
              setMainWh(whListData[0].warehouse_guid);
            }
          }
        } catch (e) {
          console.log(e);
        }
      }
    };
    getData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoStore, setMainWh, setWhList, token]);

  const getWhStocksData = useCallback(async () => {
    if (mainWh) {
      setLoadingWhStocks(true);
      const data = await getWhStocks(mainWh);
      if (data) {
        setWhStocks(data);
      }
      setLoadingWhStocks(false);
    }
  }, [mainWh, setLoadingWhStocks, setWhStocks]);

  useEffect(() => {
    // Update wh stocks every hour
    // FIXME: create 2 intervals on first login on site (when no mainWh in LS)
    getWhStocksData();
    const interval = setInterval(async () => {
      getWhStocksData();
    }, MILLISECOND_IN_HOUR);
    return () => clearInterval(interval);
  }, [getWhStocksData]);

  useEffect(() => {
    window.showReload = function () {
      setIsReloadButton(true);
    };
    window.showUploadExcel = function () {
      setIsUploadExcel(true);
    };
    return () => {
      setIsReloadButton(false);
      window.showReload = () => {};
      setIsUploadExcel(false);
      window.showUploadExcel = () => {};
    };
  }, []);

  return (
    <>
      <div className={styles.header}>{header}</div>
      <div className={styles.content}>{content}</div>
      <div className={styles.footer}>{footer}</div>
      {isReloadButton && (
        <Button
          variant='contained'
          classes={{
            root: styles.reloadButton,
            contained: styles.reloadButton,
          }}
          onClick={reload}
          disabled={isReloadingData}
        >
          {isReloadingData ? (
            <CircularProgress
              size={30}
              classes={{
                colorPrimary: styles.progressColor,
              }}
            />
          ) : (
            "Перезагрузить позиции"
          )}
        </Button>
      )}
      {isUploadExcel && (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            alignItems: "flex-start",
            position: "absolute",
            right: "15px",
            top: "90px",
            border: "1px solid red",
            padding: "10px",
            backgroundColor: "#fff",
          }}
        >
          <input
            type='file'
            onChange={handleFile}
            disabled={isUploadingExcel}
          />
          <Select
            native
            value={orderGuid}
            onChange={(event) => setOrderGuid(event.target.value as string)}
          >
            {ORDER_GUIDS.map((value) => (
              <option key={value} value={value}>
                {value}
              </option>
            ))}
          </Select>
          <Button
            variant='contained'
            onClick={uploadFile}
            disabled={isUploadingExcel || !excelFile}
          >
            {isUploadingExcel ? (
              <CircularProgress
                size={30}
                classes={{
                  colorPrimary: styles.progressColor,
                }}
              />
            ) : (
              "Загрузить"
            )}
          </Button>
        </div>
      )}
    </>
  );
};

export default MainLayout;
