import { NotificationManager } from "react-notifications";
import {
  GetReservesResponse,
  OneReserveResponse,
  ReserseResponse,
  UnreserveResponse,
} from "../models/reserves";
import { postData } from "../stores/BaseStore";

export const reserve = async (
  token: string,
  draftUuid: string
): Promise<ReserseResponse | undefined> => {
  let data: ReserseResponse | undefined;
  const response = await postData("/testnode/f/reserve", {
    SessionToken: token,
    draftUuid,
  }).ready;
  data = await response.json();
  if (!response.ok || data?.success !== "true") {
    NotificationManager.error("Ошибка при переводе в резерв");
    return;
  }

  return data;
};

export const getReserves = async (
  token: string
): Promise<OneReserveResponse[] | undefined> => {
  const response = await postData("/testnode/f/getreserves", {
    SessionToken: token,
  }).ready;
  let data: GetReservesResponse | undefined;
  if (response.ok) {
    data = await response.json();
  } else {
    NotificationManager.error("Ошибка при получении списка резервов");
  }
  return data?.res;
};

export const unReserve = async (
  session_token: string,
  order_guid: string
): Promise<UnreserveResponse | undefined> => {
  let data: UnreserveResponse | undefined;
  const response = await postData("/testnode/f/unreserve", {
    session_token,
    order_guid,
  }).ready;
  data = await response.json();
  if (!response.ok || data?.success !== "true") {
    NotificationManager.error("Ошибка при возврате в черновик");
    return;
  }

  return data;
};
