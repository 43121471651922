import { NotificationManager } from "react-notifications";
import {
  CreateDraftRequest,
  CreateDraftResponse,
  Draft2,
  DraftItem,
  DraftPosition2,
} from "../models/drafts";
import { CartPosition } from "../models/product";
import { postData } from "../stores/BaseStore";

interface UpdateDraftRequest {
  SessionToken: string;
  order_uuid: string;
  comment: string;
  credit_week: number;
  items: Array<{ uuid?: string; quantity?: number }>;
  buyer_guid?: string;
}

export async function updateDraftFromCart(
  draft: Draft2,
  cartItems: CartPosition[],
  token: string,
  comment?: string
): Promise<Draft2[] | undefined> {
  const newDraftItems =
    draft.items?.map((item) => ({
      uuid: item.uuid,
      quantity: item.quantity,
    })) || [];
  cartItems.forEach((item) => {
    const itemInDraft = newDraftItems.find(
      (draftItem) => draftItem.uuid === item.uuid
    );
    if (itemInDraft) {
      itemInDraft.quantity = itemInDraft.quantity
        ? itemInDraft.quantity + item.qty
        : item.qty;
    } else {
      newDraftItems.push({
        uuid: item.uuid,
        quantity: item.qty,
      });
    }
  });

  const requestData: UpdateDraftRequest = {
    SessionToken: token,
    order_uuid: draft?.draftuuid || "",
    comment: comment ? comment : draft.comment || "",
    credit_week: draft?.credit_week || 0,
    items: newDraftItems,
    buyer_guid: draft?.buyer_guid || undefined,
  };
  let data: { drafts: Array<Draft2> } | undefined;
  const request = postData("/testnode/f/updatedraft", requestData);
  const response = await request.ready;
  data = await response.json();
  if (!response.ok || !data?.drafts) {
    NotificationManager.error("Ошибка при обновлении черновика");
    return undefined;
  }

  return data.drafts;
}

export async function createDraftFromCart(
  token: string,
  items: Array<CartPosition>,
  org_guid: string,
  buyer_guid: string,
  adress_guid: string,
  wh_guid: string,
  comment?: string
): Promise<CreateDraftResponse | undefined> {
  const formattedItems: Array<DraftItem> = items.map((item) => ({
    sku_uuid: item.uuid,
    quantity: item.qty,
  }));
  const requestData: CreateDraftRequest = {
    session_token: token,
    org_guid,
    delivery_method: "delivery",
    buyer_guid,
    adress_guid,
    wh_guid,
    items: formattedItems,
    comment,
  };
  let data: CreateDraftResponse | undefined;
  const request = postData("/testnode/f/createdraftnew", requestData);
  const response = await request.ready;
  data = await response.json();

  if (!response.ok || data?.success !== "true") {
    NotificationManager.error("Ошибка при создании черновика");
    return undefined;
  }

  return data;
}

export async function createDraft(
  token: string,
  items: Array<DraftPosition2>,
  org_guid: string,
  buyer_guid: string,
  adress_guid: string,
  wh_guid: string,
  comment?: string
): Promise<CreateDraftResponse | undefined> {
  const formattedItems: Array<DraftItem> = items.map((item) => ({
    sku_uuid: item.uuid,
    quantity: item.quantity,
  }));
  const requestData: CreateDraftRequest = {
    session_token: token,
    org_guid,
    delivery_method: "delivery",
    buyer_guid,
    adress_guid,
    wh_guid,
    items: formattedItems,
    comment,
  };
  let data: CreateDraftResponse | undefined;
  const request = postData("/testnode/f/createdraftnew", requestData);
  const response = await request.ready;
  data = await response.json();

  if (!response.ok || data?.success !== "true") {
    NotificationManager.error("Ошибка при создании черновика");
    return undefined;
  }

  return data;
}
